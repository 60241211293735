import React from 'react';

import Page from 'containers/ApplyPage'

import ClaimProvider from 'contexts/apply/Provider'

export default () => {
  return (
    <ClaimProvider>
      <Page />
    </ClaimProvider>
  )
}
