import React, { useContext, useState, Fragment } from 'react'
import { StickyContainer, Sticky } from 'react-sticky'

import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import ContentLayout from 'components/ContentLayout'
import Modal from 'components/Modal'
import { responsive } from 'components/ThemeProvider/theme'

import applyContext from 'contexts/apply/context'
import useResponsive from 'contexts/mediaQuery/useResponsive'

import ClaimBoard from './ClaimBoard'
import ModuleList from './ModuleList'
import Popup from './Popup'

const data = [
  {
    notice: '壹、聲請調解相關注意事項',
    content:[
      {
        title: '依據鄉鎮市調解條例第1、10 條規定，調解事件之範圍: 不得聲請調解。',
        items: ['民事事件。', '告訴乃論之刑事事件。', '已在第一審法院辯論終結者，']
      },
      {
        title: '特殊爭議事件，如消費爭議、勞資爭議、三七五減租耕地租佃爭議、公害糾紛、公寓大廈爭議、建築爭議、著作權爭議、土地法上之不動產糾紛、市地重劃爭議、政府採購爭議、性騷擾事件、醫療糾紛等案件，宜向特別法令所設置之專責單位聲請調解或調處。',
      },
      {
        title: '依據鄉鎮市調解條例第11條規定，聲請調解，民事事件應得當事人之同意；告訴乃論之刑事事件應得被害人之同意，始得進行調解。',
      },
      {
        title: '依據鄉鎮市調解條例第13、35條規定，聲請調解事件之管轄如下：',
        items: ['兩造均在同一區居住者，由該區調解委員會調解。',
        '兩造不在同一區居住者，民事事件由他造住、居所、營業所、事務所所在地，刑事事件由他造住、居所所在地或犯罪地之區調解委員會調解。',
        '經兩造同意，並經接受聲請之區調解委員會同意者，得由該區調解委員會調解，不受前二款之限制。'
      ]
      },
      {
        title: '為保障您自身的權益，請聲請人於填寫線上聲請資料時，務必上傳身分證(正面+反面)檔案資料，作為承辦之調解委員會辨識您身分之重要依據。如經審查未能正確提供者，將請您補正提供身分證資料後，始能進行審查程序。',
      },
      {
        title: '依據鄉鎮市調解條例第21條規定，調解需審究事實真相及兩造爭議之所在。自您提交線上聲請資料後，承辦之調解委員會將進行聲請調解審查，如填寫內容有疑義或不明確者，調解委員會人員將透過您所提供之聯絡資料與您聯繫確認，並請您補正提供調解案件所需之相關證據資料，以確保您法律上相關權益。'
      },
    ],
  },
  {
    notice: '貳、參加調解會議應注意事項如下',
    content:[
      {
        title: '當事人請攜帶身分證、印章（得以簽名代之），如為公司或商號請攜帶公司或商號大小章及公司或商業登記證明文件。當事人或負責人無法出席者，應出具委任書委任年滿20歲之成年人代理出席。',
      },
      {
        title: '當事人如係未滿20歲之未成年人，則父母親（法定代理人）均須出席，並應攜帶身分證、印章、戶籍謄本(記事欄不得省略)。父或母親之一方未能出席者，則應提出委任書委任另一方代理出席；父母親皆未出席者，則皆應提出委任書委任年滿20歲之成年人代理出席。',
      },
      {
        title: '交通事件之當事人請一併請攜帶駕駛執照、行車執照及汽機車強制保險卡、驗傷單(診斷書)及醫療收據、維修車輛估價單或維修收據。駕駛汽機車之當事人如非車主本人，請一併通知車主本人攜帶身分證及印章（得以簽名代之）到場。如車主無法到場，請車主出具委任書委託年滿20歲之成年人代理出席。如另有投保第三人責任保險者，請當事人自行通知保險公司理賠人員到場協同調解。',
      },
      {
        title: '如有涉及土地或房屋等不動產糾紛者，請攜帶土地、建物登記簿謄本、地籍圖或土地鑑界圖（均限三個月內）及土地分割方案等資料。',
      },
    ]
  }
]

const StickyBox = props => (
  <Sticky topOffset={-94}>
    {({ style, isSticky }) => <Box style={style} pt={isSticky && 94} {...props} />}
  </Sticky>
)

const ClaimIndex = () => {
  const { applyData } = useContext(applyContext)
  const [open, setOpen] = useState()
  const { isMobile } = useResponsive()
  const Wrapper = isMobile ? Fragment : StickyContainer
  return (
    <Box overflow={open && 'hidden'}>
      <ContentLayout title="線上聲請調解服務系統" pt={responsive('2.875', '3.75em')} pb={responsive('6.25em', '7.5em')}>
        <Wrapper>
          <Flex flexDirection={responsive('column', 'row')} alignItems={responsive('center', 'flex-start')} mt="3em">
            <Box flex={1} mx="1.4375em">
              <Text fontSize="1.625em" mb={responsive('1.5rem', '2.25rem')}>聲請前請先閱讀</Text>
              <ModuleList data={data} />
            </Box>
            <Box width={responsive(1, '16.5em')} mx="1.4375em">
              <Box as={isMobile ? 'div' : StickyBox}>
                {applyData.map(({ src, text, key }) => (
                  <ClaimBoard
                    src={src}
                    text={text}
                    setOpen={() => setOpen(key)}
                    key={key}
                    isMobile={isMobile}
                  />
                ))}
              </Box>
            </Box>
          </Flex>
        </Wrapper>
      </ContentLayout>
      <Modal
        closeIcon
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        p={responsive('2.625em 0.875em', '2.625em 2.5em', '5.75em 10em')}
      >
        <Popup id={open}  />
      </Modal>
    </Box>
  )
}

export default ClaimIndex
