import React from 'react'

import Box from 'components/Box'
import Image from 'components/Image'
import Text from 'components/Text'
import Button from 'components/Button'
import Board from 'components/Board'
import { responsive } from 'components/ThemeProvider/theme';

const ClaimBoard = ({ src, text, setOpen, isMobile, ...props }) => {
  return (
    <Board
      bg="lightPurple"
      mb={responsive(0, '1.875em')}
      mt={responsive('1.875em', 0)}
      px="2em"
      pb="2.875em"
      pt="2.375em"
      onClick={setOpen}
      {...props}
    >
      <Box width="4.25em" mx="auto">
        <Image src={src} alt={text} />
      </Box>
      <Button fontSize="1.625em" my="1em">{text}</Button>
      <Text>聲請人已詳細審閱明白{isMobile ? '上' : '左'}述<br />各注意事項並明白與同意</Text>
    </Board>
  )
}

export default ClaimBoard
